<template>
  <div class="w-full flex flex-1 flex-col">
    <!-- Header -->
    <div class="p-30">
      <h3 class="text-base md:text-24 leading-tight font-bold font-heading text-gray-800">
        Privacy Policy & Terms and conditions
      </h3>

      <div class="w-full mt-24">
        <p class="mt-24">Effective Date: May 22, 2022.</p>

        <p class="mt-24">
          Crypto Ninja, including any affiliates and subsidiaries (“Crypto Ninja,” “we,” “us,” or “our”)
          values the trust you place in us when you use our affiliated websites and online services
          (collectively, our “Websites”).
          This Privacy Policy describes our collection, use, disclosure, and retention of information we collect
          from or about you.
          This Privacy Policy applies to our Websites that post or link to it, regardless of how you access or use them,
          including through mobile devices.
        </p>

        <p class="mt-24">
          BY USING OUR WEBSITES, YOU ARE ACCEPTING THE TERMS OF THIS PRIVACY POLICY AND OUR
          <AppButton
            variant="link"
            size="inline"
            :to="{ name: 'terms' }"
          >
            TERMS OF USE
          </AppButton>,
          AND YOU ARE CONSENTING TO OUR COLLECTION, USE, DISCLOSURE, AND RETENTION OF INFORMATION
          AS DESCRIBED IN THIS PRIVACY POLICY.
        </p>

        <p class="mt-24">
          If you do not agree to the terms of this Privacy Policy, please do not use our Websites.
          We may make changes to this Privacy Policy from time to time. We will post any changes to our Websites.
          Your continued use of our Websites following the posting of any changes will mean you accept those changes.
        </p>

        <p class="mt-24">
          This Privacy Policy does not apply to information that you may submit to third-party websites or
          mobile applications that may link to the Websites or be linked to on the Websites.
          We are not responsible for the actions or privacy practices of third-party websites and applications;
          please consult those websites and applications directly to understand their privacy practices.
        </p>

        <p class="mt-24">
          <strong>INFORMATION WE COLLECT ABOUT YOU</strong>
        </p>

        <p class="mt-24">
          We collect, process, and retain information from you and any devices you may use when you use or interact with
          our Websites, and in other ways described below.
        </p>

        <p class="mt-24">
          <strong>Information You Give Us:</strong>
          We receive and store information provided to us or given to us in any other way,
          including your: full name, email address and any other information that you voluntarily provide.
          This includes information that you share with us on third-party websites and platforms.
        </p>

        <p class="mt-24">
          <strong>Information We Automatically Collect:</strong>
          We receive and store certain types of information whenever you use our Websites.
          Our Websites may use “cookies,” tagging and other tracking technologies to help enable us to
          enhance or personalize your online experience. This information includes computer and connection
          information such as statistics on your page views, traffic to and from our Websites, referral URL,
          ad data, your IP address, device identifiers, transaction history, and your web log information.
        </p>

        <p class="mt-24">
          <strong>Location Information:</strong>
          When you visit our Websites or interact with our online services,
          we may receive information about your location and your device, including a
          unique identifier for your device. Location information allows us to provide location-based services,
          such as advertising and other personalized content. In addition, if you scan one of
          our QR codes, we will know the event, venue, or business associated with that code and, thus,
          your location at the time you scan the QR code.
        </p>

        <p class="mt-24">
          <strong>Information From Social Media:</strong>
          When you interact with us or our Websites on a social media platform, we may collect the
          personal information that you make available to us, including your account ID, username, and other
          information included in your posts. If you choose to log into your account with or through a social
          networking service, we and that service may share certain information about you and your activities.
          When you give us permission, we may also collect information from your social media account on your behalf.
        </p>

        <p class="mt-24">
          <strong>Information From Other Sources:</strong>
          We may supplement the information we collect with information offline or from third parties.
        </p>

        <p class="mt-24">
          <strong>HOW WE USE INFORMATION WE COLLECT FROM OR ABOUT YOU</strong>
        </p>

        <p class="mt-24">
          We use the information we collect from or about you to help us personalize and continually
          improve your experience on the Websites, including providing our services and conferences,
          responding to requests for information, analyzing and compiling trends and statistics, and
          communicating with you. For information about how to manage information about you and the choices you
          have, see the <strong>Your Choices</strong> section below.
        </p>

        <p class="mt-24">
          We also may use information about you to: provide, maintain, and improve the Websites
          and for internal or other
          business purposes; run and administer our virtual and in-personal conferences; fulfill your requests for
          information; communicate with you; detect, investigate, and prevent activities that may violate our
          policies or be fraudulent or illegal; optimize, perform analysis, or improve our Websites, products,
          services, and operations; provide, produce, and ship the products that you order or the services you
          request; provide customer support; send you offers or other communications about our products and services,
          such as special or promotional events, including services, products, or events for which we collaborate or
          co-offer with a third party; administer contests, sweepstakes, promotions, and surveys; and to post
          user generated content provided by users.
        </p>

        <p class="mt-24">
          <strong>HOW WE SHARE YOUR INFORMATION WITH OTHERS</strong>
        </p>

        <p class="mt-24">
          We share information we collect from or about you with third parties as described below.
          In addition, we may share information that does not personally identify you, such as aggregate
          information, de-identified information, device identifiers, or other unique identifiers with third parties.
          For information about how to manage information about you and the choices you have, see the
          <strong>Your Choices</strong> section below.
        </p>

        <p class="mt-24">
          <strong>Third-Party Service Providers:</strong>
          We engage third-party service providers to perform functions on our behalf such as:
          hosting, content syndication, content management, technical integration, marketing, analytics,
          customer service, fraud protection, payment processing, fulfillment, and shipping.
          These third parties may have access to information about you when needed to perform their functions.
        </p>

        <p class="mt-24">
          <strong>Business Partners:</strong>
          We may share information that personally identifies you with business partners
          who provide products and services that we think you may be interested in.
        </p>

        <p class="mt-24">
          <strong>Sale, Assignment or Change of Control:</strong>
          We may change our ownership or corporate organization while providing the Websites.
          We may transfer some or all information about you in connection with, or during negotiations of,
          any merger, acquisition, sale of assets, or any line of business, change in ownership control, or
          financing transaction. Under such circumstances, we will request the acquiring party to follow the
          practices described in this Privacy Policy with respect to previously collected information.
          Nevertheless, we cannot promise that an acquiring party or the merged entity will have the same privacy
          practices or treat information about you in the same way as described in this Privacy Policy.
        </p>

        <p class="mt-24">
          <strong>Law Enforcement, Legal Process, and Emergency Situations:</strong>
          We may also use or disclose information we collect from or about you if required to do so by law or
          on the good-faith belief that such action is necessary to (a) conform to applicable law or comply with
          legal process served on us or our Websites; (b) protect and defend our rights or property,
          the Websites, or our users; (c) respond to a third party that alleges that you have infringed their
          intellectual property rights; or (d) act to protect the personal safety of us, users of
          the Websites, or the public.
        </p>

        <p class="mt-24">
          <strong>Public Areas, Interactive Features and Activities:</strong>
          Please remember that any information you share in public areas, including in your public profile,
          or on social sharing features becomes public and anyone may take and use that information.
          Please be careful about what you disclose and do not post any information that you expect to keep private.
          Please consult the applicable guidelines, if any, for use of these features. We cannot control who views
          information that you post in public areas of the Websites or on third-party messaging platforms,
          like Discord, and are not responsible for how third parties, including other users may use or disclose
          information that you post or otherwise share with other users or third parties. If you are under the age
          of eighteen and a registered user, you may have a content or information you have posted removed from public
          display by deleting the message yourself or contacting the community manager.
        </p>

        <p class="mt-24">
          <strong>At Your Direction:</strong>
          Our Websites may give you the ability to share certain information with other users or other
          third parties, such as by scanning your “My Code.” When you engage these features on our
          Websites, you are authorizing us to share your information. Once shared, we have no control
          how those third parties will use or further disclose your information.
        </p>

        <p class="mt-24">
          <strong>HOW TO ACCESS AND UPDATE YOUR INFORMATION</strong>
        </p>

        <p class="mt-24">
          f you have an account with us, you can update information you have provided by logging
          into your account and editing the information. Please note that you may not be able
          to change some information, like your username or email, after your account is established.
          You may be required to provide us with sufficient information to verify your identity prior
          to accessing any records containing information about you. We may not accommodate a request to change
          information about you if we believe doing so would violate any law or legal requirement or cause the
          information to be incorrect.
        </p>

        <p class="mt-24">
          <strong>COOKIES AND OTHER TRACKING TECHNOLOGIES</strong>
        </p>

        <p class="mt-24">
          Like many websites, we use tracking technologies such as cookies, web beacons,
          and similar technologies to record your preferences, track the use of our Websites, your
          interactions with our emails, and measure exposure to our online advertisements.
          We may also use these technologies to monitor traffic, improve the Websites, and make it easier to
          use and more relevant. Most web browsers automatically accept cookies but, if you prefer,
          you can usually modify your browser setting to disable or reject cookies. In addition, you may also
          render some web beacons unusable by rejecting or removing their associated cookies. If you delete
          your cookies or if you set your browser to decline cookies, some features of the Websites may not work
          or may not work as designed.
        </p>

        <p class="mt-24">
          We use third-party analytics services on the Websites, including Google Analytics.
          These analytics service providers use cookies or other tracking technologies to help us analyze how users
          interact with and use our Websites, compile reports about activity on Websites, and provide us other services.
          The technologies may collect information such as your IP address, time of visit, whether you
          are a return visitor, any referring website, general location, and other information.
          We do not use these analytics service providers to gather information that personally identifies you.
          The information generated by Google Analytics will be transmitted to and stored by Google and will be subject
          to Google’s
          <AppButton
            variant="link"
            size="inline"
            href="https://policies.google.com/privacy"
          >
            privacy policies
          </AppButton>. To learn more about Google’s partner services and to learn how to opt
          out of tracking of analytics by Google click
          here
          <AppButton
            variant="link"
            size="inline"
            href="https://policies.google.com/technologies/partner-sites"
          >
            here
          </AppButton>.
        </p>

        <p class="mt-24">
          We work with third-party companies who also use these technologies to provide advertisements
          on our Websites and other third-party websites. These third parties may use these
          technologies to collect information about you when you use the Websites.
          They may collect information about your online activities over time and across different
          websites and other online services. They may also use persistent identifiers to track
          your Internet usage across other websites in their networks beyond our Websites.
          They may use this information to provide you with interest-based advertising or other targeted content.
          While we do not knowingly provide these entities with information that personally identifies you,
          such third parties may, with sufficient data from other sources, be able to personally identify you,
          unknown to us. More information about how to opt-out of these cookies is available
          in the <strong>Choices</strong> section below.
        </p>

        <p class="mt-24">
          The cookies used on our Websites are divided into four general categories:
        </p>

        <p class="mt-24">
          <i>Necessary:</i>
          Cookies that are essential to make our Websites usable by enabling basic functions like page
          navigation, access to secures areas of the Website, and whether you have opted-out of
          certain non-necessary cookies. The Websites cannot function properly without these cookies.
          These mandatory cookies cannot be de-selected from our consent manager.
        </p>

        <p class="mt-24">
          <i>Preferences:</i>
          Cookies that enable our Websites to remember information that changes the way
          the Websites behaves or looks, like your preferred language or region that you are in.
        </p>

        <p class="mt-24">
          <i>Analytics and Statistics:</i>
          Cookies that help us understand how visitors interact
          with our Websites by collecting and reporting about your device’s interactions.
        </p>

        <p class="mt-24">
          <i>Marketing:</i>
          Used to track devices across websites to display ads that are relevant
          and engaging to our visitors to show you our ads on
          third-party websites. You may also deny or delete these cookies via your browser settings.
        </p>

        <p class="mt-24">
          Some content, applications, and advertisements on the Websites may be served by unaffiliated third parties.
          We do not control these third parties’ tracking technologies or how they may be used.
          We are not responsible for the content or privacy practices on any website not operated by us to
          which our Websites links or that links to our Websites.
        </p>

        <p class="mt-24">
          Your browser or device may include “Do Not Track” functionality.
          Our information collection, disclosure practices,
          and the choices that we provide, will continue to operate as described in this Privacy Policy,
          whether or not a Do Not Track signal is received.
        </p>

        <p class="mt-24">
          <strong>YOUR CHOICES ABOUT HOW INFORMATION ABOUT YOU IS USED AND SHARED</strong>
        </p>

        <p class="mt-24">
          In many instances, you have choices about the information you provide and how we use that information.
          These choices are described below. California residents and data subjects in Europe have additional
          rights as set forth in the sections entitled <strong>Notice to Residents of California</strong> and <strong>to
            Residents of the European Economic Area, Switzerland, and United Kingdom</strong> below.
        </p>

        <p class="mt-24">
          <strong>Marketing Emails:</strong>
          By providing us with an e-mail address, you acknowledge that we may use your e-mail address to
          communicate with you. While you cannot opt-out of receiving notifications and other service-related
          communications regarding your account or your transactions, you can opt-out of receiving promotional
          and other marketing emails from us by using the “unsubscribe” feature in our marketing e-mails.
        </p>

        <p class="mt-24">
          <strong>Interest-Based Advertising Choices:</strong>
          You also have choices related to interest-based advertising. Many browsers automatically accept cookies,
          but you can usually modify your browser’s setting to decline cookies if you prefer.
          Note that if you choose to remove cookies, you may remove opt-out cookies that affect
          your advertising preferences.
        </p>

        <p class="mt-24">
          Many of the third-party advertisers that place tracking tools on our Websites are members
          of programs that offer you additional choices regarding the use of information about you for
          advertising. You can learn more about the options available to limit
          these third parties’ use of your information by visiting the websites for the
          <AppButton
            variant="link"
            size="inline"
            href="https://optout.networkadvertising.org/?c=1"
          >
            Network Advertising Initiative
          </AppButton> and the <AppButton
            variant="link"
            size="inline"
            href="https://optout.aboutads.info/?c=2&lang=EN"
          >
            Digital Advertising Alliance
          </AppButton>.
        </p>

        <p class="mt-24">
          Similarly, you can learn about your options to opt-out of mobile app
          tracking by certain advertising networks through your device settings. For more information
          about how to change these settings, see the support information for
          <AppButton
            variant="link"
            size="inline"
            href="https://www.apple.com/legal/privacy/data/en/apple-advertising/"
          >
            Apple
          </AppButton>,
          <AppButton
            variant="link"
            size="inline"
            href="https://policies.google.com/technologies/ads"
          >
            Android
          </AppButton> or
          <AppButton
            variant="link"
            size="inline"
            href="https://account.microsoft.com/privacy/ad-settings/signedout"
          >
            Windows
          </AppButton> devices.
        </p>

        <p class="mt-24">
          Please note that opting-out of participating advertising networks services does not mean
          that you will not receive advertising while using our Websites or on other websites, nor will it
          prevent the receipt of interest-based advertising from third parties that do not participate in
          these industry programs.
        </p>

        <p class="mt-24">
          <strong>NOTICE TO RESIDENTS OF CALIFORNIA</strong>
        </p>

        <p class="mt-24">
          This section is part of our Privacy Policy but applies only to residents of California.
          State law provides you with certain rights to receive disclosure regarding the collection, use,
          and sharing of “personal information,” as well as rights to access, delete, and restrict the sale
          of certain personal information we collect about you. You or your authorized agent may submit a request
          to exercise these rights by sending us an email at
          <AppButton
            variant="link"
            size="inline"
            href="mailto:privacy@cryptocoin.com"
          >
            privacy@cryptocoin.com <!-- TODO !!! -->
          </AppButton>. You have the right not to receive
          discriminatory treatment for the exercise of the privacy rights conferred by applicable law.
        </p>

        <p class="mt-24">
          <strong>Collection and Use of Personal Information:</strong>
          The general sections of this Privacy Policy describe the types of personal information we collect,
          how we collect it, and how we use it. The following is a list of categories of personal information
          that we may have collected from or about consumers and the sources of that information:
        </p>

        <p class="mt-24">
          <i>Identifiers:</i>
          Name, alias, postal address, unique personal identifier, online identifier, IP address,
          email address, account name, or other similar identifiers. This information is provided by you,
          automatically collected when you interact with our Website, or is provided by a third party.
        </p>

        <p class="mt-24">
          <i>Customer records:</i>
          Name, address, telephone number, education, employment, or payment account number.
          This information is provided by you, automatically collected when you interact with our
          Website, or is provided by a third party.
        </p>

        <p class="mt-24">
          <i>Protected classifications:</i>
          Race, sexual orientation, gender identity, or disability.
          This information is provided by you, such as voluntarily disclosed as
          part of job application.
        </p>

        <p class="mt-24">
          <i>Commercial information:</i>
          Records of products or services purchased, obtained, or considered, or other purchasing or
          consuming histories or tendencies. This information is automatically
          collected based on your interactions with our Website, your purchases, and your subscriptions.
        </p>

        <p class="mt-24">
          <i>Internet or other electronic network activity information:</i>
          Browsing history, search history, and information regarding interactions with our Website or advertisements.
          This information is automatically collected when you interact with our Website, or is provided by a
          third party, such as an advertising or analytics provider.
        </p>

        <p class="mt-24">
          <i>Geolocation:</i>
          General or precise location information. This is provided by you when you provide
          you address or other location information, scan a QR code or check-in at a location,
          or inferred based on your IP address.
        </p>

        <p class="mt-24">
          <i>Professional and education data:</i>
          Employer, title, professional license numbers, work history, and educational background.
          This information is provided by you, such as your conference registration
          or job application, or is provided by a third party.
        </p>

        <p class="mt-24">
          <i>Inferences:</i>
          Information about your preferences, characteristics, predisposition,
          demographic info, attitudes, and favorites.
          This information is provided by you, automatically collected when you interact
          with our Website, or is provided by a third party.
        </p>

        <p class="mt-24">
          We collect and use the above categories of personal information for the purposes
          described above in the sections entitled <strong>How We Use Information We Collect
            From or About You, How We Share Your Information with Others</strong>, and <strong>
            Cookies and Other Tracking Technologies.</strong>
          We limited disclosures of personal information for business purpose to services providers, as described above.
        </p>

        <p class="mt-24">
          <strong>Sale of Personal Information and Right to Opt Out:</strong>
          State law requires companies to include certain disclosures including a
          “Do Not Sell My Personal Info” links on their websites. We do not knowingly sell the personal
          information of consumers under 16 years of age. To opt out of the sale to third parties,
          please sending us an email at
          <AppButton
            variant="link"
            size="inline"
            href="mailto:contacts@gocryptoninja.com "
          >
            contacts@gocryptoninja.com  <!-- TODO !!! -->
          </AppButton>
          and using the cookie manager on our Website
          to disable non-necessary cookies. Please note that your right to opt out does not apply to our sharing
          of data with service providers, with whom we work and who are required to use the data on our behalf.
        </p>

        <p class="mt-24">
          The following categories of personal information that are disclosed for a business
          purpose or “sold” and the related categories of third parties:
        </p>

        <p class="mt-24">
          <i>Identifiers:</i>
          Name, email, unique personal identifiers, online identifiers, IP address,
          or other similar identifiers to affiliates, business
          partners, advertising and marketing companies, data analytics providers, and social networks.
        </p>

        <p class="mt-24">
          <i>Internet or other electronic network activity information:</i>
          info regarding your interaction with an internet website, application,
          or advertisement to affiliates, business partners, advertising and marketing companies,
          data analytics providers, and social networks.
        </p>

        <p class="mt-24">
          <strong>Rights Requests:</strong>
          As noted above, you have the right to request that we disclose what personal information we
          collect, use, and sell, as well as the right to request that we delete certain personal
          information that we have collected from you. You or your authorized agent may submit a
          request to exercise these rights by sending us an email at
          <AppButton
            variant="link"
            size="inline"
            href="mailto:contacts@gocryptoninja.com "
          >
            contacts@gocryptoninja.com  <!-- TODO !!! -->
          </AppButton>. We will require you
          to verify your identity before we can act on your request. You are required to provide your name and email
          address, your cell phone number if you have sign-up to received text messages from us,
          or wallet address if you have
          linked that with your account to start the verification process.
          If you are asking for access on behalf of someone else,
          we will require verification of your identity, as well as proof of authorization by the
          individual whose personal
          information you wish to access. There may be information that we will not return in response to you request,
          such as information that would affect the privacy of others or interfere with legal obligations. Similarly,
          there may be reasons why we cannot comply with your deletion request, such as the need to keep your personal
          information to provide you service or to fulfil a legal obligation.
        </p>

        <p class="mt-24">
          <strong>Financial Incentives:</strong>
          We may run promotions from time to time and ask you to share personal information with us.
          We will always give you clear notice about these types of programs when you sign up, and
          participation is always voluntary. If you change your mind, you will be able to opt out,
          and if you do not participate, you will still be able to use our services.
        </p>

        <p class="mt-24">
          <strong>California “Shine the Light” Rights:</strong>
          If you are a California resident and customer, California law permits you to
          request certain information regarding the disclosure of your information about you by us and our
          related companies to third parties for the third parties' direct marketing purposes.
          To make such a request, please sending us an email at
          <AppButton
            variant="link"
            size="inline"
            href="mailto:contacts@gocryptoninja.com "
          >
            contacts@gocryptoninja.com  <!-- TODO !!! -->
          </AppButton>.
        </p>

        <p class="mt-24">
          <strong>NOTICES TO RESIDENTS OF THE EUROPEAN ECONOMIC AREA, SWITZERLAND, AND UNITED KINGDOM</strong>
        </p>

        <p class="mt-24">
          This section is part of our Privacy Policy but applies only to residents of the
          European Economic Area (EEA), Switzerland, and United Kingdom.
          For purposes of data protection law, Crypto Ninja is the controller.
        </p>

        <p class="mt-24">
          <strong>Purposes of Processing:</strong>
          As described in the other sections of our Privacy Policy, we process your personal
          data to administer our Websites, process your requests, facilitate access by our service providers
          and third parties, send marketing communications, respond to your requests in connection with
          employment opportunities, transfer or sell our business, to combine data sets, and for the other purposes
          set forth in our Privacy Policy. Also as explained above, we may also use,
          transfer, and otherwise use personal
          data acquired from you directly or by use of cookies or other
          tracking technologies to remember your preferences,
          conduct analytics, and serve you with advertising.
        </p>

        <p class="mt-24">
          <strong>Legal Basis:</strong>
          We have several different legal grounds on which we collect and process personal data,
          including (a) as necessary to perform a transaction (such as registering for a conference, subscribing
          to our newsletters, inquiring about employment, or responding to your requests); (b) as
          necessary to comply with a legal obligation; (c) where you have provided consent; and (d) necessary for
          legitimate interest when such processing is reasonably expected by you and does not unduly and negatively
          affect your privacy rights. If you would like to withdraw your consent, please use the options that are
          described in the <strong>Your Choices</strong> section above.
          Please keep in mind that additional basis my still exist for processing that
          is not based on your consent.
        </p>

        <p class="mt-24">
          <strong>Transfers:</strong>
          Your personal data may be transferred to and processed in the United States or
          elsewhere in the world that may not provide the same level of data protection as your
          home country. We will ensure that transfers of personal data are subject to appropriate
          safeguards in accordance with applicable legal requirements to ensure that your data is
          adequately protected.
        </p>

        <p class="mt-24">
          <strong>Your Rights:</strong>
          You have certain rights under applicable law. These rights include (1)
          to obtain information about the processing of your personal data and a copy of your
          personal data that we store; (2)
          to request that we update your personal data if it is inaccurate or incomplete; (3) to request the deletion of
          your personal data if it is no longer necessary for the purposes for which it was collected or if
          you withdraw consent and no other legal basis for processing exists; (4) to restrict
          the processing if you contest
          the accuracy of your personal data, if our processing is deemed unlawful and you oppose deletion, or if
          we no longer need the personal data but must store it to comply with our legal
          obligations; (5) to receive your
          personal data and to transfer it to another controller; (6) to object to our processing
          when we process your personal
          data based on legitimate interest; and (7) to lodge a complaint about our collection or
          processing of your personal
          data with your Data Protection Authority (DPA).
        </p>

        <p class="mt-24">
          If you would like to exercise rights (1) through (6),
          please sending us an email at privacy@coindesk.com. To verify your identity,
          we may require you to provide us with personal data prior to accessing any records about you.
          If you would like to file a complaint with your DPA, contact details for the DPAs
          in the EEA and UK are available at
          <AppButton
            variant="link"
            size="inline"
            href="ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
            class="break-all"
            style="text-align: left;"
          >
            ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
          </AppButton> and for Switzerland at
          <AppButton
            variant="link"
            size="inline"
            href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html"
            class="break-all"
            style="text-align: left;"
          >
            https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html.
          </AppButton>
        </p>

        <p class="mt-24">
          You are not required to provide all personal data identified in this Privacy Policy
          to use our Websites, purchase our services, or to interact with us offline, but certain
          functionality may not be available to you. If you do not provide your personal information,
          we may not be able to respond to your requests, perform a transaction with you, or provide you with
          marketing that we believe you would find valuable. We do not use automated decision-making without
          human intervention, including profiling, in a way that procedures legal effects concerning you or
          otherwise significantly affects you.
        </p>

        <p class="mt-24">
          <strong>OTHER IMPORTANT INFORMATION</strong>
        </p>

        <p class="mt-24">
          <strong>Users Under Thirteen:</strong>
          Our Websites are intended for users ages thirteen and older only.
          If we become aware of information in our database that was collected from a
          child under thirteen, we will delete such information.
        </p>

        <p class="mt-24">
          <strong>Users Outside of the United States:</strong>
          If you use our Websites outside of the United States, you understand and consent to the
          transfer of information we collect from or about you to, and the collection, processing,
          and storage of information about you in, the United States and elsewhere.
          The laws in the U.S. and these countries may be different than the laws of your country.
        </p>

        <p class="mt-24">
          <strong>For questions about our Privacy Policy, you can contact us at
            <AppButton
              variant="link"
              size="inline"
              href="mailto:contacts@gocryptoninja.com "
            >
              contacts@gocryptoninja.com
            </AppButton>.
          </strong>
        </p>

        <p class="mt-24">
          All rights reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import AppButton from '@/components/stateless/AppButton.vue'

export default defineComponent({
  name: 'PrivacyPolicy',

  components: {
    AppButton
  },

  setup () {
    return {
    }
  }
})
</script>
